import React from "react";
import { Container, GlassHubLink } from "./styles";

const GatsbyWikiLink =
  "https://eu001-sp.shell.com/sites/SPO000420/SitePages/Glass.aspx#meet-the-team";

export default function MadeByComponent() {
  return (
    <Container>
      Made by the
      <GlassHubLink href={GatsbyWikiLink} target="_blank">
        Glass Hub
      </GlassHubLink>
      Team
    </Container>
  );
}
