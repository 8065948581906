import { useEffect, useState } from "react";
import useAppContext from "./useAppContext";

const useProducts = () => {
  const [loading, setLoading] = useState(true);
  const { state, getProducts, searchProducts } = useAppContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        if (state.searchText) {
          await searchProducts(state.searchText, state.activeFolder?.id);
        } else {
          await getProducts(state.activeFolder?.id);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [state.searchText, getProducts, searchProducts, state.activeFolder?.id]);

  return {
    filteredApps: state.products.products,
    count: state.products.totalItems,
    loading,
  };
};

export default useProducts;
