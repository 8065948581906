import React from "react";
import { Container, AppsContainer, Title, TitleContainer } from "./styles";
import DataProductMultiItem from "../DataProductMultiItem";
import DataProductLoadingItem, {
  DataProductLoadingHeader,
} from "../DataProductLoadingItem";

const itemOptions = ({ items, totalItems, loading, onClickShowAll }) => {
  if (loading) {
    return <DataProductLoadingItem />;
  }

  return (
    <DataProductMultiItem
      productsNumber={totalItems}
      onClick={onClickShowAll}
    />
  );
};

export default function DataProductSection({
  items = [],
  title = "No title",
  showTitle,
  loading,
  totalItems,
  onClickShowAll,
  visible,
}) {
  return (
    visible && (
      <Container>
        {showTitle &&
          (loading ? (
            <DataProductLoadingHeader />
          ) : (
            <TitleContainer>
              <Title size="xsmall">{title}</Title>
            </TitleContainer>
          ))}
        <AppsContainer>
          {itemOptions({ items, totalItems, loading, onClickShowAll })}
        </AppsContainer>
      </Container>
    )
  );
}
