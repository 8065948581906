import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
  font-size: 12px;
  padding: 8px 0px;
`;

export const GlassHubLink = styled.a`
  margin-left: 4px;
  margin-right: 4px;
  color: ${({ theme }) => theme.text.onSurface.link};
  text-decoration: underline;
`;
