import * as React from "react";

const CarbonTrendIcon = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="#404040"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d={`M14.406 2.25a.5.5 0 0 0-.5.5v.5a.5.5 0 0 0 .5.5h1.287l-3.697 3.74a.2.2 0 0 1-.276-.003L10.47 
      6.26l-.002-.002a1.7 1.7 0 0 0-2.374 0l-3.799 3.75a.5.5 0 0 0-.004.707l.35.356a.5.5 0 0 0 .708.004L9.143 
      7.33a.199.199 0 0 1 .277 0l1.25 1.229.003.003a1.701 1.701 0 0 0 2.372 0l.01-.01 3.851-3.896V6.25a.5.5 
      0 0 0 .5.5h.5a.5.5 0 0 0 .5-.5v-3.5a.5.5 0 0 0-.5-.5h-3.5ZM5 13.25a.75.75 0 0 1 .696.472l2 5A.75.75 
      0 0 1 7 19.75H6v1.75a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1.75H3a.75.75 0 0 1-.696-1.029l2-5A.75.75 
      0 0 1 5 13.25Zm.892 5L5 16.02l-.892 2.23h1.784Zm6.83-6.456a.75.75 0 0 0-1.443 0l-2 7a.75.75 0 0 0 .721.956h1v1.75a.5.5 
      0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1.75h1a.75.75 0 0 0 .721-.956l-2-7ZM13 18.25h-2.006L12 14.73l1.006 3.52H13Zm6-9a.75.75 
      0 0 1 .732.587l2 9a.75.75 0 0 1-.732.913h-1v1.75a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1.75h-1a.75.75 0 0 1-.732-.913l2-9A.75.75 
      0 0 1 19 9.25Zm-1 9h2.065L19 13.457l-1.065 4.793H18Z`}
    />
  </svg>
);

export default CarbonTrendIcon;
