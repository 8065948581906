import styled from "styled-components";
import { Heading, Button, Label } from "@sede-x/shell-ds-react-framework";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 450px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px 24px 16px 24px;
  overflow-y: auto;
`;

export const ImageContainer = styled.img`
  height: 150px;
`;

export const ButtonStyled = styled(Button)`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 24px 24px 24px;
`;

export const HeadingTitle = styled(Heading)`
  font-weight: 600;
  max-width: 216px;
  text-align: center;
`;

export const LabelText = styled(Label)`
  margin-top: 4px;
  max-width: 216px;
  text-align: center;
  color: ${({ theme }) => theme.text.onSurface.subtle};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  align-items: center;
  margin-top: 12px;
`;
