import { useCallback, useContext } from "react";
import { AppContext } from "../context/appContext";
import ProductService from "../services/ProductService";
import DataProducService from "../services/DataProductService";
import StateActions from "../context/StateActions";
import FeatureFlagService from "../services/FeatureFlagsService";

const useAppContext = () => {
  const { state, dispatch } = useContext(AppContext);

  const getProducts = useCallback(
    async (parentId) => {
      const products = await ProductService.getProducts(parentId);
      dispatch({ type: StateActions.SET_PRODUCTS, value: products });
    },
    [dispatch]
  );

  const getFolderProducts = useCallback(
    async (parentId) => {
      const products = await ProductService.getFolderProducts(parentId);
      dispatch({ type: StateActions.SET_PRODUCTS, value: products });
    },
    [dispatch]
  );

  const searchProducts = useCallback(
    async (searchText, parentId) => {
      const products = await ProductService.searchProducts(
        searchText,
        parentId
      );
      dispatch({ type: StateActions.SET_PRODUCTS, value: products });
    },
    [dispatch]
  );

  const getInfoProduct = useCallback(
    async (id) => {
      const product =
        state.products.products.find((p) => p.id === id) || state.activeFolder;
      dispatch({ type: StateActions.GET_INFO_PRODUCT, value: product });
    },
    [dispatch, state.products.products, state.activeFolder]
  );

  const getInfoFolder = useCallback(
    async (id) => {
      dispatch({
        type: StateActions.GET_INFO_PRODUCT,
        value: state.activeFolder,
      });
    },
    [dispatch, state.activeFolder]
  );

  const cleanInfoProduct = useCallback(async () => {
    dispatch({ type: StateActions.CLEAN_INFO_PRODUCT });
  }, [dispatch]);

  const setSearchText = useCallback(
    async (text) => {
      dispatch({ type: StateActions.SET_SEARCH_FILTER, value: text });
    },
    [dispatch]
  );

  const setActiveFolder = useCallback(
    async (folder) => {
      setSearchText("");
      const f = state.products.products.find((p) => p.id === folder?.id);
      dispatch({ type: StateActions.SET_ACTIVE_FOLDER, value: f });
    },
    [dispatch, state.products.products]
  );

  const getDataProducts = useCallback(
    async (searchText) => {
      dispatch({ type: StateActions.CLEAN_DATA_PRODUCTS });
      const products = await DataProducService.searchProducts({
        keyword: searchText,
        limit: 3,
        offset: 0,
        sort: "name",
      });
      dispatch({
        type: StateActions.GET_DATA_PRODUCTS,
        value: products,
      });
    },
    [dispatch]
  );

  const cleanDataProducts = useCallback(async () => {
    dispatch({ type: StateActions.CLEAN_DATA_PRODUCTS });
  }, [dispatch]);

  const getFeatureFlags = useCallback(async () => {
    const featureFlags = await FeatureFlagService.getFeatureFlags();
    dispatch({ type: StateActions.GET_FEATURE_FLAGS, value: featureFlags });
  }, [dispatch]);

  return {
    state,
    dispatch,
    getProducts,
    searchProducts,
    getInfoProduct,
    cleanInfoProduct,
    setSearchText,
    getDataProducts,
    cleanDataProducts,
    getFeatureFlags,
    getFolderProducts,
    setActiveFolder,
    getInfoFolder,
  };
};

export default useAppContext;
