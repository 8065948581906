import React from "react";

const NetworkIcon = (props) => (
  <svg
    width={28}
    height={28}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d={`M16.667 3.333c0 1.243-.85 2.287-2 2.583v4.14a4.003 4.003 0 0 1 3.278 3.277h4.139a2.668 2.668 0 0 1 5.25.667 
      2.667 2.667 0 0 1-5.25.667h-4.14a4.003 4.003 0 0 1-3.277 3.278v4.14a2.668 2.668 0 1 1-1.334 0v-4.14a4.003 4.003 0 0 1-3.278-3.278H5.916a2.668 
      2.668 0 1 1 0-1.334h4.14a4.003 4.003 0 0 1 3.277-3.278V5.916a2.668 2.668 0 1 1 3.334-2.583Z`}
      fill="#a1a4a6"
    />
  </svg>
);

export default NetworkIcon;
