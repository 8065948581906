import getVar from "../services/env-vars";

export function generatePurviewSearchURL(query) {
  const purviewBaseUrl = getVar("PURVIEW_URL");
  return [
    `https://web.purview.azure.com/resource/${purviewBaseUrl}/main/catalog/search?`,
    `searchQueryKeyword=${query}&searchQueryLimit=25&searchQueryOffset=0&searchQueryOrderBy=name&`,
    `searchQueryOrderByDesc=false&`,
  ].join("");
}

export function generateDataXplorerURL(query) {
  const dataxplorerBaseUrl = getVar("DATAXPLORER_URL");
  return `${dataxplorerBaseUrl}/?search=${query}`;
}
