import React, { useCallback } from "react";
import Icon from "../../icons";
import {
  Container,
  LabelElement,
  SubLabelElement,
  Avatar,
  TextContainer,
  IconDetailsContainer,
} from "./styles";
import { Icons } from "@sede-x/shell-ds-react-framework";
import useAppContext from "../../hooks/useAppContext";
export default function Item({
  id,
  title,
  description,
  icon,
  extra,
  link,
  visible,
  allowInfo,
  folder,
  parent,
  external,
}) {
  const { getInfoProduct, setActiveFolder } = useAppContext();
  // this should be replaced for the adobe analytics function when the adobe analytics team
  // finishes the integration.
  function AdobeAnalytics(CTA) {
    window.digitalData = window.digitalData || [];
    window.digitalData.push({
      event: "content",
      metric: "engage",
      CTA,
    });
  }

  function redirectUser(link) {
    if (link) {
      if (external) {
        window.open(link, "_blank");
      } else {
        window.top.location.href = link;
      }
    }
  }

  function logAndRedirect(CTA, link) {
    AdobeAnalytics(CTA);
    redirectUser(link);
  }

  const onClickInfo = useCallback(
    (e) => {
      e.stopPropagation();
      if (id) {
        getInfoProduct(id);
      }
    },
    [getInfoProduct, id]
  );

  const onClickFolder = useCallback(() => {
    if (id) {
      setActiveFolder({ id: id, title: title });
    }
  }, [id, setActiveFolder, title]);

  return visible ? (
    <Container
      onClick={() => logAndRedirect(title, link)}
      rel="noopener noreferrer"
    >
      <Avatar>
        <Icon alt={"icon"} icon={folder ? "FolderSolid" : icon ?? extra} />
      </Avatar>
      <TextContainer onClick={folder ? onClickFolder : null}>
        <LabelElement size="small">{title}</LabelElement>
        <SubLabelElement size="small">{description}</SubLabelElement>
      </TextContainer>
      {allowInfo && !folder && (
        <IconDetailsContainer onClick={onClickInfo}>
          <Icons.InfoCircle width={16} height={16} />
        </IconDetailsContainer>
      )}
      {folder && (
        <IconDetailsContainer onClick={onClickFolder}>
          <Icons.ChevronRight width={16} height={16} />
        </IconDetailsContainer>
      )}
    </Container>
  ) : (
    <></>
  );
}
