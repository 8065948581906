import React from "react";

const CertificateIcon = (props) => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d={`M0.947368 0C0.424151 0 0 0.402945 0 0.9V13.95C0 16.1868 1.90868 18 4.26316 18H11.8421C15.243 
      18 16 15.3809 16 12.15V9.9C16 9.40294 15.5758 9 15.0526 9H12.3158V0.9C12.3158 0.402943 11.8916 0 11.3684 
      0H0.947368ZM12.3158 17.0798V9.9H15.0526V12.15C15.0526 14.7321 14.9715 16.8525 12.3158 17.0798ZM10.4211 4.05C10.4211 
      4.54706 9.9969 4.95 9.47368 4.95H2.84211C2.31889 4.95 1.89474 4.54706 1.89474 4.05C1.89474 3.55294 2.31889 3.15 2.84211 
      3.15H9.47368C9.9969 3.15 10.4211 3.55294 10.4211 4.05ZM9.47368 9.45C9.9969 9.45 10.4211 9.04706 10.4211 8.55C10.4211 8.05294 
      9.9969 7.65 9.47368 7.65H2.84211C2.31889 7.65 1.89474 8.05294 1.89474 8.55C1.89474 9.04706 2.31889 9.45 2.84211 9.45H9.47368ZM10.4211 
      13.05C10.4211 13.5471 9.9969 13.95 9.47368 13.95H2.84211C2.31889 13.95 1.89474 13.5471 1.89474 13.05C1.89474 12.5529 2.31889 12.15 2.84211 
      12.15H9.47368C9.9969 12.15 10.4211 12.5529 10.4211 13.05Z`}
      fill="white"
      fillOpacity="0.57"
    />
  </svg>
);

export default CertificateIcon;
