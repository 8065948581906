import { useState } from "react";
import {
  InfoContainer,
  Container,
  ButtonContainer,
  LabelText,
  HeadingTitle,
  ButtonStyled,
  ImageContainer,
  TextContainer,
} from "./styles";
import DotsStepper from "../../components/DotsStepper";
import React from "react";

function NewsSection({ onComplete, currentNews }) {
  const [currentStep, setCurrentStep] = useState(1);
  const currentValue = currentNews[currentStep - 1];

  const handleClick = () => {
    if (currentStep === currentNews.length) {
      onComplete && onComplete(currentNews);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };
  return (
    <Container>
      <InfoContainer>
        <ImageContainer src={currentValue.image} />
        <TextContainer>
          <HeadingTitle type="h5">{currentValue.header}</HeadingTitle>
          <LabelText size="small">{currentValue.text}</LabelText>
        </TextContainer>
      </InfoContainer>
      <ButtonContainer>
        <DotsStepper
          currentStep={currentStep}
          maxSteps={currentNews?.length || 0}
          changeStep={(step) => setCurrentStep(step)}
        />
        <ButtonStyled
          variant="filled"
          fullWidth
          onClick={handleClick}
          data-testid="newsButton"
        >
          {currentStep < currentNews?.length ? "Next" : "Sounds good"}
        </ButtonStyled>
      </ButtonContainer>
    </Container>
  );
}

export default NewsSection;
