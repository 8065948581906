import React from "react";
import {
  Container,
  LabelElement,
  SubLabelElement,
  Avatar,
  TextContainer,
  IconDetailsContainer,
} from "./styles";
import Icon from "../../icons";
import { CrosshairSolid } from "@sede-x/shell-ds-react-framework/build/cjs/components/Icon/components";

export default function DataProductMultiItem({ productsNumber, onClick }) {
  const hasMultipleResults = productsNumber > 1;

  const productsNumberText = hasMultipleResults
    ? `${productsNumber} data products found`
    : `${productsNumber} data product found`;

  const viewProductsText = hasMultipleResults
    ? "View all"
    : "View data product";
  return (
    <Container onClick={onClick} rel="noopener noreferrer">
      <Avatar>
        <Icon icon="Search" />
      </Avatar>
      <TextContainer>
        <LabelElement size="small">{productsNumberText}</LabelElement>
        <SubLabelElement size="small">{viewProductsText}</SubLabelElement>
      </TextContainer>
      <IconDetailsContainer>
        <CrosshairSolid width={20} height={20} />
      </IconDetailsContainer>
    </Container>
  );
}
