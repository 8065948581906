import React from "react";
import {
  Container,
  AvatarLoading,
  TextContainerLoading,
  LabelElementLoading,
  SubLabelElementLoading,
  TitleContainerLoading,
} from "./styles";

export default function DataProductLoadingItem() {
  return (
    <Container>
      <AvatarLoading />
      <TextContainerLoading>
        <LabelElementLoading />
        <LabelElementLoading />
        <SubLabelElementLoading />
      </TextContainerLoading>
    </Container>
  );
}

export function DataProductLoadingHeader() {
  return <TitleContainerLoading />;
}
