import styled from "styled-components";
import { Heading, Text, Button, Label } from "@sede-x/shell-ds-react-framework";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const BackToPreviousContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 16px 24px 16px 24px;
`;

export const BackArrow = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px 24px 16px 24px;
  max-height: 300px;
  overflow-y: auto;
`;

export const BackToPreviousText = styled(Text)`
  font-weight: 400;
  font-size: 11px;
  margin-left: 8px;
`;

export const HeadingTitle = styled(Heading)`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`;

export const HeadingSpaced = styled(Heading)`
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.text.onSurface.subtle};
`;

export const LinkContact = styled.a`
  margin-left: 8px;
  color: ${({ theme }) => theme.text.onSurface.link};
  text-decoration: underline;
`;

export const LabelContact = styled(Label)`
  margin-top: 11px;
  font-weight: 400;
  color: ${({ theme }) => theme.text.onSurface.subtle};
`;

export const TextSpaced = styled(Text)`
  margin-top: 16px;
  color: ${({ theme }) => theme.text.onSurface.subtle};
`;

export const ButtonStyled = styled(Button)`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

export const ButtonLiked = styled(Button)`
  display: flex;
  justify-content: center;
  margin-top: 8px;
`;
