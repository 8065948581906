import image from "./images/dataProductsImage.png";

const DataProducts = {
  id: "glasshub-dataproducts",
  image,
  header: "Search for data products in Glass Hub",
  text: "Find and retrieve data from the Data exchange directly within the Glass Hub.",
};

export default DataProducts;
