import { useEffect, useState } from "react";
import useAppContext from "./useAppContext";
import Flags from "../utils/featureFlags";

const isAvailable = (featureFlags, flag) => {
  return !!featureFlags.find((ff) => ff.feature_name === flag)?.available;
};

const useFeatureFlags = () => {
  const [loading, setLoading] = useState(true);
  const { state, getFeatureFlags } = useAppContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (state.featureFlags.length <= 0) {
          setLoading(true);
          getFeatureFlags();
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [getFeatureFlags]);

  return {
    pinUnpinFlag: isAvailable(state.featureFlags, Flags.PIN_UNPIN),
    dxSearchFlag: isAvailable(state.featureFlags, Flags.DATA_SEARCH_DXP),
    loading,
  };
};

export default useFeatureFlags;
