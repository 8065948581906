import styled from "styled-components";
import { Label } from "@sede-x/shell-ds-react-framework";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  flex: 1;
  padding-top: 36px;
  padding-bottom: 36px;
`;

export const TitleLabel = styled(Label)`
  margin-top: 12px;
  font-weight: bold;
`;

export const DescriptionLabel = styled(Label)`
  margin-top: 4px;
`;
