import styled from "styled-components";
import { Divider, Icons, Link } from "@sede-x/shell-ds-react-framework";

export const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  flex: 1;
`;
export const Content = styled.div`
  display: flex;
  flex: 1;
`;

export const StyledLink = styled(Link)`
  padding: 0px 7px;
`;

export const CenteredContent = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  padding-top: 64px;
  width: 1000px;
`;

export const OptionsIcon = styled(Icons.Options)`
  vertical-align: middle;
  width: 18px;
`;

export const InfoIcon = styled(Icons.InfoCircle)`
  vertical-align: middle;
  width: 18px;
`;

export const FavIcon = styled(Icons.HeartHollow)`
  vertical-align: middle;
  width: 18px;
`;

//styled div that is the main content of the page
export const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  background-color: ${({ theme }) => theme.background.base};
  margin: 0px;
`;

export const Sized = (width) => styled.div`
  display: flex;
  width: ${width}px;
`;

// styled component align self right
export const AlignRight = styled.div`
  display: flex;
  align-self: flex-end;
`;

// styled component background theme surface
export const Raised = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: row;
  background-color: ${({ theme }) => theme.background.surface};
`;

// styled component background theme surface
export const Background = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${({ theme }) => theme.background.surface};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2), 0px 6px 24px rgba(0, 0, 0, 0.28);
`;

// returns a style component with a certain padding
export const Padded = (padding) => styled.div`
  padding: ${padding}px;
`;

// returns a style component flexbox column direction
export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

// returns a style component flexbox Row direction
export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

// return a styled component with position absolute
export const Absolute = ({ top = 0, right = 0 }) => styled.div`
  position: absolute;
  top: ${top}px;
  right: ${right}px;
`;

export const AlignLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 10px;
  flex: 1;
`;

export const HubContainer = styled.div`
  width: 100%;
`;

export const SpacedBetween = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px;
  flex: 1;
`;

const sideStyle =
  (side) =>
  ({ [side]: innerSide }) =>
    innerSide ? `${innerSide}px` : 0;

export const DividerSpaced = styled(Divider)`
  margin-top: ${sideStyle("top")};
  margin-bottom: ${sideStyle("bottom")};
`;

export const SpacedCentered = styled.div`
  margin-left: ${sideStyle("left")};
  margin-right: ${sideStyle("right")};
`;
