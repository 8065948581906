import React from "react";

const GraphIcon = (props) => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d={`M17.033 3.99973C18.1224 3.98209 19 3.09354 19 2C19 0.89543 18.1046 0 17 0C15.8954 0 15 0.89543 15 2C15 2.37623 15.1039 2.7282 15.2845 
      3.02878L11.967 9.00027C11.5128 9.0076 11.0954 9.16636 10.7631 9.42825L8.97984 8.71495C8.84149 7.7454 8.00779 7 7 7C5.89543 7 5 7.89543 5 9C5 
      9.29559 5.06413 9.5762 5.17922 9.82869L2.19314 14.0092C2.12958 14.0031 2.06515 14 2 14C0.89543 14 0 14.8954 0 16C0 17.1046 0.89543 18 2 18C3.10457 
      18 4 17.1046 4 16C4 15.7044 3.93589 15.4239 3.82081 15.1714L6.80695 10.9908C6.87048 10.9969 6.93488 11 7 11C7.46693 11 7.89649 10.84 8.23683 
      10.5718L10.0202 11.2851C10.1586 12.2546 10.9922 13 12 13C13.1046 13 14 12.1046 14 11C14 10.6238 13.8961 10.2719 13.7155 9.97129L17.033 3.99973Z`}
      fill="white"
      fillOpacity="0.57"
    />
  </svg>
);

export default GraphIcon;
