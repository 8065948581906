import useAppContext from "../../hooks/useAppContext";
import { Icons, Divider } from "@sede-x/shell-ds-react-framework";
import {
  BackArrow,
  BackToPreviousContainer,
  Container,
  InnerContainer,
  HeadingTitle,
  HeadingSpaced,
  LinkContact,
  ButtonStyled,
  LabelContact,
  BackToPreviousText,
  ButtonLiked,
} from "./styles";
import React, { useCallback } from "react";
import useLocalStorage from "../../hooks/useLocalStorage";

function DetailsSection({ isPinUnpinAvailable }) {
  const { cleanInfoProduct, state } = useAppContext();
  const [likedState, setLikedState] = useLocalStorage("liked");

  const AdobeAnalytics = (pinning) => (CTA) => {
    window.digitalData = window.digitalData || [];
    window.digitalData.push({
      event: pinning ? "addFav" : "removeFav",
      metric: "engage",
      prodSelected: CTA,
    });
  };

  const handleLike = (id, title) => () => {
    const n = Date.now();
    setLikedState([...(likedState ?? []), { id, n: n }]);
    AdobeAnalytics(true)(title);
  };

  const handleDisLike = (id, title) => () => {
    setLikedState([...likedState.filter((e) => e.id !== id)]);
    AdobeAnalytics(false)(title);
  };

  const {
    title,
    longDescription,
    responsible,
    responsibleLink,
    link,
    id,
    external,
  } = state.currentProduct;

  const isLiked = likedState
    ? likedState.find((ls) => ls.id === id)
    : undefined;

  const onClickBack = useCallback(() => {
    cleanInfoProduct();
  }, [cleanInfoProduct]);

  const onClickGoPage = useCallback(() => {
    if (link) {
      if (external) {
        window.open(link, "_blank");
      } else {
        window.top.location.href = link;
      }
    }
  }, [link, external]);

  return (
    <Container>
      <BackToPreviousContainer>
        <BackArrow onClick={onClickBack}>
          <Icons.ArrowLeft width={16} height={16} />
          <BackToPreviousText size="small">Back</BackToPreviousText>
        </BackArrow>
      </BackToPreviousContainer>
      <Divider />
      <InnerContainer>
        <HeadingTitle type="h4">{title}</HeadingTitle>
        <HeadingSpaced type="h5">{longDescription}</HeadingSpaced>
        <LabelContact size="medium">
          Focal Point:
          <LinkContact href={responsibleLink} target="_blank">
            {responsible}
          </LinkContact>
        </LabelContact>
        <ButtonStyled variant="filled" onClick={onClickGoPage}>
          Go to product
        </ButtonStyled>
        {isPinUnpinAvailable && (
          <ButtonLiked
            variant="outlined"
            onClick={isLiked ? handleDisLike(id, title) : handleLike(id, title)}
            icon={isLiked ? <Icons.HeartFilled /> : <Icons.HeartHollow />}
            iconPosition="left"
          >
            {isLiked ? "Remove from favourites" : "Add to favourites"}
          </ButtonLiked>
        )}
      </InnerContainer>
    </Container>
  );
}

export default DetailsSection;
