import GraphIcon from "./GraphIcon";
import CertificateIcon from "./CertificateIcon";
import FireIcon from "./FireIcon";
import GaugeIcon from "./GaugeIcon";
import NetworkIcon from "./NetworkIcon";
import { Icons } from "@sede-x/shell-ds-react-framework";
import { Container } from "./styles";
import CarbonTrendIcon from "./CarbonTrendIcon";

const mappings = {
  certificate: <CertificateIcon />,
  fire: <FireIcon />,
  gauge: <GaugeIcon />,
  graph: <GraphIcon />,
  network: <NetworkIcon />,
  carbontrend: <CarbonTrendIcon />,
};

export default function Icon({ icon, width, height }) {
  const SelectIcon = Icons[icon] ?? Icons.Lightbulb;
  return (
    <Container width={width} height={height}>
      {mappings[icon?.toLowerCase()] ?? <SelectIcon />}
    </Container>
  );
}
