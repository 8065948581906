import styled from "styled-components";
import { Divider } from "@sede-x/shell-ds-react-framework";

export const ScrollableContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 320px;
  min-height: 320px;
  overflow-y: auto;
`;

export const StyledDivider = styled(Divider)`
  margin-top: 16px;
  margin-bottom: 16px;
  border-bottom: 0.5px solid ${({ theme }) => theme.border.strong};
`;
