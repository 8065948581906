import getVars from "./env-vars";

const url = getVars("MASTERMIND_URL");

const getFeatureFlags = async () => {
  const result = await fetch(`${url}/featureflags`, {
    method: "GET",
  });
  if (result.status >= 400) {
    return [];
  }
  const json = await result.json();
  return json.items;
};

const FeatureFlagService = {
  getFeatureFlags,
};

export default FeatureFlagService;
