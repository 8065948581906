import React from "react";
import { Container, AppsContainer, Title, TitleContainer } from "./styles";
import Item from "../Item/";

export default function Section({ items = [], title = "No title", showTitle }) {
  return (
    <Container>
      {showTitle && (
        <TitleContainer>
          <Title size="xsmall">{title}</Title>
        </TitleContainer>
      )}
      <AppsContainer>
        {items.map((app) => (
          <Item key={app?.title} allowInfo {...app} />
        ))}
      </AppsContainer>
    </Container>
  );
}
