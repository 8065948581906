import React from "react";

const FireIcon = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d={`M14.912 8.87a16.374 16.374 0 0 0-2.669-4.822C10.26 1.588 7.653 0 5.6 0a.756.756 0 0 0-.352.088.814.814 0 0 0-.28.245.917.917 0 0 0-.142.739c.693 
      2.839-.744 5.2-2.266 7.699C1.3 10.84 0 12.979 0 15.429 0 20.155 3.589 24 8 24s8-3.845 8-8.571c0-2.32-.366-4.527-1.088-6.56ZM9.616 
      20.714c-.516.59-1.163.714-1.616.714-.452 0-1.1-.124-1.617-.713C5.867 20.125 5.6 19.19 5.6 18c0-1.347.441-2.372.867-3.363.248-.576.5-1.161.65-1.788a.44.44 
      0 0 1 .091-.183.4.4 0 0 1 .161-.116.374.374 0 0 1 .373.057c.457.38.863.824 1.208 1.322.909 1.285 1.45 2.808 1.45 4.071 0 1.191-.271 2.13-.784 2.715Z`}
    />
  </svg>
);

export default FireIcon;
