import styled from "styled-components";
import { Label } from "@sede-x/shell-ds-react-framework";

export const Container = styled.div`
  padding: 8px 16px 8px 16px;
  display: flex;
  flex-direction: row;
  &:hover {
    background-color: ${({ theme }) => theme.background.raised};
    svg path {
      fill-opacity: 1;
    }
  }
  cursor: pointer;
  user-select: none;
  svg path {
    fill: ${({ theme }) => theme.text.onSurface.subtle};
    fill-opacity: 0.57;
  }
  &:hover {
  }
`;

export const Avatar = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.background.section};
  border-radius: 50%;
  align-self: center;
  justify-content: center;
  align-items: center;
`;

export const LabelElement = styled(Label)`
  cursor: pointer;
  text-transform: none;
  font-weight: 600;
`;

export const SubLabelElement = styled(Label)`
  cursor: pointer;
  text-transform: none;
  color: ${({ theme }) => theme.text.onSurface.subtle};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
  flex: 1;
`;

export const IconDetailsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: flex-end;
  width: 32px;
`;
