import getVars from "./env-vars";

const url = getVars("MASTERMIND_URL");

const getProducts = async (parentId) => {
  const parentString = parentId ? `parent=${parentId}` : "";

  const result = await fetch(`${url}/products?${parentString}&limit=1000`, {

    method: "GET",
  });

  if (result.status >= 400) {
    return { totalItems: 0, products: [] };
  }
  const json = await result.json();
  return { totalItems: json.totalItems, products: json.items };
};

const searchProducts = async (searchText, parentId) => {
  const parentString = parentId ? `&parent=${parentId}` : "";
  const result = await fetch(
    `${url}/products/search?query=${searchText}${parentString}`,
    {
      method: "GET",
    }
  );
  if (result.status >= 400) {
    return { totalItems: 0, products: [] };
  }
  const json = await result.json();
  return { totalItems: json.totalItems, products: json.items };
};

const ProductService = {
  getProducts,
  searchProducts,
};

export default ProductService;
