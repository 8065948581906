import { generateParams } from "./utils";
import getVars from "./env-vars";

const url = getVars("MASTERMIND_URL");

const searchProducts = async ({ keyword, limit, offset, sort }) => {
  const result = await fetch(
    `${url}/dataproducts/?${generateParams({
      keywords: keyword,
      limit: limit,
      offset: offset,
      sort: sort,
    })}`
  );
  if (result.status >= 400) {
    return { totalItems: 0, products: [] };
  }
  const json = await result.json();
  return { totalItems: json.totalCount, products: json.dataProducts };
};

const DataProducService = {
  searchProducts,
};

export default DataProducService;
