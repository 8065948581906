import React from "react";
import { Container, Dot, ClickZone } from "./styles";

export default function DotsStepper({ currentStep, maxSteps, changeStep }) {
  return (
    <Container>
      {Array.from(Array(maxSteps)).map((i, index) => (
        <ClickZone
          key={index}
          onClick={() => changeStep(index + 1)}
          role="button"
        >
          <Dot selected={index === currentStep - 1} />
        </ClickZone>
      ))}
    </Container>
  );
}
