import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Dot = styled.div`
  background: ${({ selected }) => (selected ? "white" : "grey")};
  width: ${({ selected }) => (selected ? "4px" : "5px")};
  height: ${({ selected }) => (selected ? "4px" : "5px")};
  border-radius: 50%;
`;

export const ClickZone = styled.div`
  padding: 4px;
  cursor: pointer;
`;
