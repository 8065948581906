/* eslint-disable react-hooks/exhaustive-deps */
import useAuth, { AuthDone, AuthLoading } from "@sede-x/glass-auth-sso";
import { Loading, ShellThemeProvider } from "@sede-x/shell-ds-react-framework";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AppContextProvider } from "./context/appContext";
import HubLight from "./screens/HubLight";

const Hub = React.lazy(() => import("./screens/Hub"));
const Main = React.lazy(() => import("./screens/Main"));
const Embed = React.lazy(() => import("./screens/EmbedScreen"));

const provider = "https://sso.dep.shell/auth/realms/dep";
const clientId = "glasshub-noprod";
const redirect = `${window.location.origin}/authentication/callback/`;

function App() {
  const [theme, setTheme] = useState("dark");
  const auth = useAuth();
  const location = window.location.pathname;
  const authRequired = location !== "/hub" && location !== "/hub-light";

  useEffect(() => {
    if (authRequired) {
      auth.login({ provider, clientId, redirect });
    }
  }, [location]);

  const toggleTheme = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };

  return (
    <ShellThemeProvider theme={theme}>
      <AppContextProvider>
        <React.Suspense fallback={<Loading />}>
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                  <Main toggleTheme={toggleTheme} token={auth.accessToken} />
                }
              />
              <Route path="/hub" element={<Hub />} />
              <Route path="/hub-light" element={<HubLight />} />
              <Route
                path="/embed"
                element={<Embed token={auth.accessToken} />}
              />
              <Route path="/authentication/callback/" element={<AuthDone />} />
              <Route
                path="/authentication/loading/"
                element={<AuthLoading />}
              />
            </Routes>
          </BrowserRouter>
        </React.Suspense>
      </AppContextProvider>
    </ShellThemeProvider>
  );
}

export default App;
