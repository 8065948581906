import React from "react";
import NotFoundSection from "../../components/NotFoundSection";
import SearchBar from "../../components/SearchBar";
import Section from "../../components/Section";
import useAppContext from "../../hooks/useAppContext";
import useProducts from "../../hooks/useProducts";
import useDataProducts from "../../hooks/useDataProducts";
import { ScrollableContainer, StyledDivider } from "./styles";
import DataProductSection from "../../components/DataProductSection";
import {
  generatePurviewSearchURL,
  generateDataXplorerURL,
} from "../../utils/generateSearchURL";
import useLocalStorage from "../../hooks/useLocalStorage";
import { Divider, Icons, Text } from "@sede-x/shell-ds-react-framework";
import { ParentContainer } from "../../components/Section/styles";

function SearchHubSection({ isSearchDXAvailable, isPinUnpinAvailable }) {
  const { state, setSearchText, setActiveFolder, getInfoFolder } =
    useAppContext();
  const { filteredApps, loading } = useProducts();
  const {
    dataProducts,
    loading: dataProductsLoading,
    totalProducts,
  } = useDataProducts({ available: true });

  const showApps = filteredApps.length > 0;
  const showDataProducts = !!state.searchText && dataProducts.length > 0;

  const onClickGoToAllDatasets = () => {
    if (state.searchText) {
      if (isSearchDXAvailable) {
        window.open(generateDataXplorerURL(state.searchText));
      } else {
        window
          .open(generatePurviewSearchURL(state.searchText), "_blank")
          .focus();
      }
    }
  };

  const [likedState] = useLocalStorage("liked");

  const getLikedApps = (likedState) => {
    const likedIds = likedState?.map((s) => s.id) ?? [];
    return filteredApps
      .filter((app) => likedIds.includes(app.id))
      .sort((a, b) => {
        const getTimeStamp = (v) =>
          likedState.find((lS) => v.id === lS.id)?.n || Date.now();
        return getTimeStamp(a) - getTimeStamp(b);
      });
  };

  const getNotLikedApps = (likedState) => {
    const likedIds = likedState?.map((s) => s.id) ?? [];
    return filteredApps.filter((app) => !likedIds.includes(app.id));
  };

  const likedApps = isPinUnpinAvailable ? getLikedApps(likedState) : [];
  const notLikedApps = isPinUnpinAvailable
    ? getNotLikedApps(likedState)
    : filteredApps;

  return (
    <>
      {state.activeFolder && (
        <>
          <ParentContainer>
            <Icons.ArrowLeft
              onClick={() => {
                setActiveFolder(undefined);
              }}
            />
            <Text>{state.activeFolder?.title}</Text>
            <Icons.InfoCircle onClick={() => getInfoFolder()} />
          </ParentContainer>
          <Divider />
        </>
      )}

      <SearchBar
        value={state.searchText}
        onChange={(value) => {
          setSearchText(value);
        }}
      />
      {showApps || showDataProducts || loading || dataProductsLoading ? (
        <ScrollableContainer>
          {(showDataProducts || dataProductsLoading) && (
            <DataProductSection
              items={dataProducts}
              title="Data Products"
              totalItems={totalProducts}
              showTitle
              loading={dataProductsLoading}
              onClickShowAll={onClickGoToAllDatasets}
              visible={!!state.searchText}
            />
          )}

          {showDataProducts &&
          (likedApps?.length > 0 || notLikedApps?.length > 0) ? (
            <StyledDivider size="small" />
          ) : null}
          {likedApps?.length > 0 && (
            <Section
              items={likedApps}
              title="Favourites"
              showTitle={true}
              liked={true}
            />
          )}
          {likedApps?.length > 0 && notLikedApps?.length > 0 ? (
            <StyledDivider size="small" />
          ) : null}
          {notLikedApps?.length > 0 ? (
            <Section
              items={notLikedApps}
              title="Applications"
              showTitle={likedApps?.length > 0 || showDataProducts}
            />
          ) : null}
        </ScrollableContainer>
      ) : (
        <ScrollableContainer>
          <NotFoundSection searchText={state.searchText} />
        </ScrollableContainer>
      )}
    </>
  );
}

export default SearchHubSection;
