import { TextInput, Icons } from "@sede-x/shell-ds-react-framework";
import React, { useCallback } from "react";
import { TextInputContainer } from "./styles";

export default function SearchBar({ value, onChange }) {
  const handleChange = useCallback(
    (event) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  return (
    <TextInputContainer>
      <TextInput
        icon={<Icons.Search height={24} />}
        placeholder="Search for apps and data products"
        value={value}
        onChange={handleChange}
      />
    </TextInputContainer>
  );
}
