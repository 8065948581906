import { useEffect, useState } from "react";
import useAppContext from "./useAppContext";
import { useDebounce } from "use-debounce";

const useDataProducts = ({ available }) => {
  const [loading, setLoading] = useState(false);
  const { state, getDataProducts, cleanDataProducts } = useAppContext();
  const [debouncedText] = useDebounce(state.searchText, 500);

  useEffect(() => {
    if (available) {
      if (state.searchText) {
        setLoading(true);
      } else {
        cleanDataProducts();
        setLoading(false);
      }
    }
  }, [state.searchText, cleanDataProducts]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getDataProducts(debouncedText);
      } finally {
        setLoading(false);
      }
    };
    if (available) {
      if (!!debouncedText) {
        fetchData();
      } else {
        cleanDataProducts();
      }
    }
  }, [debouncedText, cleanDataProducts, getDataProducts]);

  return {
    totalProducts: loading ? 0 : state.dataProducts.totalItems,
    dataProducts: loading ? [] : state.dataProducts.products,
    loading,
  };
};

export default useDataProducts;
