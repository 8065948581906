import styled, { keyframes } from "styled-components";

const startColor = ({ theme }) => theme.background.surface;
const endColor = ({ theme }) => theme.text.onSurface.subtle;

export const Container = styled.div`
  padding: 8px 16px 8px 16px;
  display: flex;
  flex-direction: row;
  user-select: none;
`;

export const TextContainerLoading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
  flex: 1;
`;

export const ShineAnimation = keyframes`
  0% {background-position: top right;}
  100% {background-position: top left;}
`;

const LoadingItem = styled.div`
  display: flex;
  background: ${startColor} -webkit-gradient(
      linear,
      left top,
      right top,
      from(${startColor}),
      to(${startColor}),
      color-stop(0.5, ${endColor})
    ) 0 0 no-repeat;
  background-size: 2000px;
  background-clip: content-box;
  animation-name: ${ShineAnimation};
  animation-duration: 4s;
  animation-iteration-count: infinite;
`;
export const AvatarLoading = styled(LoadingItem)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

export const LabelElementLoading = styled(LoadingItem)`
  width: 226px;
  height: 8px;
  border-radius: 8px;
  margin-bottom: 5px;
`;

export const SubLabelElementLoading = styled(LoadingItem)`
  width: 60px;
  height: 8px;
  border-radius: 8px;
`;

export const TitleContainerLoading = styled(LoadingItem)`
  margin: 4px 16px 4px 16px;
  width: 119px;
  height: 10px;
  border-radius: 8px;
`;
