const actions = {
  GET_INFO_PRODUCT: "GET_INFO_PRODUCT",
  SET_PRODUCTS: "SET_PRODUCTS",
  CLEAN_INFO_PRODUCT: "CLEAN_INFO_PRODUCT",
  SET_SEARCH_FILTER: "SET_SEARCH_FILTER",
  GET_DATA_PRODUCTS: "GET_DATA_PRODUCTS",
  CLEAN_DATA_PRODUCTS: "CLEAN_DATA_PRODUCTS",
  LOGIN_USER: "LOGIN_USER",
  GET_FEATURE_FLAGS: "GET_FEATURE_FLAGS",
  SET_ACTIVE_FOLDER: "SET_ACTIVE_FOLDER",
};

export default actions;
