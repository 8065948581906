import styled from "styled-components";

const sizeStyles =
  (direction) =>
  ({ [direction]: dir }) =>
    `${dir ?? 30}px`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  flex: 1;
  width=${sizeStyles("width")};
  height=${sizeStyles("height")};
  svg path {
    fill: ${({ theme }) => theme.text.onSurface.strong};
    fill-opacity: 0.57;
  }
`;
