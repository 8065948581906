import image from "./images/favouritesImage.png";

const PinAndUnpin = {
  id: "glasshub-pinandunpinnews",
  image,
  header: "Add applications to your favourites list",
  text: "Clicking on the “i” icon will allow you to add your favourite apps on the Glass Hub to your curated favourites list.",
};

export default PinAndUnpin;
