import React, { useEffect } from "react";
import {
  HubContainer,
  Background,
  DividerSpaced,
  SpacedCentered,
} from "../components/layout";
import adobeAnalytics from "../services/adobeAnalytics";
import useAppContext from "../hooks/useAppContext";
import SearchHubSection from "./SearchHubSection";
import DetailsSection from "./DetailsSection";
import MadeByComponent from "../components/MadeByComponent";
import useLocalStorage from "../hooks/useLocalStorage";
import NewsSection from "./NewsSection";
import currentNews from "../news/currentNews";
import { ShellThemeProvider } from "@sede-x/shell-ds-react-framework";
import useFeatureFlags from "../hooks/useFeatureFlags";

function HubLight() {
  const { state } = useAppContext();
  const [newsState, setNewsState] = useLocalStorage("glasshub-news");

  const { pinUnpinFlag, dxSearchFlag } = useFeatureFlags();

  const newsNotShowed = currentNews.filter((i) => {
    if (newsState) {
      return !newsState.find((nSi) => nSi.id === i.id);
    }
    return true;
  });

  // create a use effect that triggers one time calling engageEvent
  useEffect(() => {
    adobeAnalytics("pageLoad", "attract");
  }, []);

  return (
    <ShellThemeProvider theme="light">
      <HubContainer>
        <Background>
          {newsNotShowed.length === 0 ? (
            <>
              {state.currentProduct.id ? (
                <DetailsSection isPinUnpinAvailable={pinUnpinFlag} />
              ) : (
                <SearchHubSection
                  isSearchDXAvailable={dxSearchFlag}
                  isPinUnpinAvailable={pinUnpinFlag}
                />
              )}
              <SpacedCentered left={16} right={16}>
                <DividerSpaced top={16} />
              </SpacedCentered>
              <MadeByComponent />
            </>
          ) : (
            <NewsSection
              currentNews={newsNotShowed}
              onComplete={(news) => {
                if (newsState) {
                  setNewsState([...newsState, ...news]);
                } else {
                  setNewsState(news);
                }
              }}
            />
          )}
        </Background>
      </HubContainer>
    </ShellThemeProvider>
  );
}

export default HubLight;
