import React from "react";

const GaugeIcon = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d={`M10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C2.00035 3.85752 1.26375 
      4.95991 0.761204 6.17317C0.258657 7.38642 0 8.68678 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 
      20C11.3132 20 12.6136 19.7413 13.8268 19.2388C15.0401 18.7362 16.1425 17.9997 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 
      7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 
      18 7.87827 18 10C18 12.4 17 14.5 15.3 16C13.9 14.7 12 14 10 14C8 14 6.2 14.7 4.7 16C3 14.5 2 12.4 2 10C2 7.87827 2.84285 5.84344 4.34315 
      4.34315C5.84344 2.84285 7.87827 2 10 2Z`}
      fill="white"
      fillOpacity="0.57"
    />
    <path
      d={`M12 4.85C11.62 4.86 11.26 5.11 11.1 5.5L8.14 10.22C7.73 11.25 8.23 12.41 9.26 
      12.82C10.29 13.23 11.45 12.73 11.86 11.7L12.97 6.22C13.17 5.71 12.92 5.13 12.41 4.92C12.28 4.87 12.15 4.85 12 4.85Z`}
      fill="white"
      fillOpacity="0.57"
    />
  </svg>
);

export default GaugeIcon;
