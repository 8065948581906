import React, { createContext, useReducer } from "react";
import StateActions from "./StateActions";

export const AppContext = createContext();

const initialState = {
  currentProduct: {},
  searchText: undefined,
  dataProducts: { totalItems: 0, products: [] },
  featureFlags: [],
  products: { totalItems: 0, products: [] },
  user: {},
  activeFolder: undefined,
};

const reducers = {
  [StateActions.SET_PRODUCTS]: (state, action) => {
    return {
      ...state,
      products: action?.value,
    };
  },
  [StateActions.GET_INFO_PRODUCT]: (state, action) => {
    return {
      ...state,
      currentProduct: action?.value,
    };
  },
  [StateActions.CLEAN_INFO_PRODUCT]: (state) => {
    return {
      ...state,
      currentProduct: {},
    };
  },
  [StateActions.SET_SEARCH_FILTER]: (state, action) => {
    return {
      ...state,
      searchText: action?.value,
    };
  },
  [StateActions.GET_DATA_PRODUCTS]: (state, action) => {
    return {
      ...state,
      dataProducts: action?.value,
    };
  },
  [StateActions.CLEAN_DATA_PRODUCTS]: (state) => {
    return {
      ...state,
      dataProducts: { totalItems: 0, products: [] },
    };
  },
  [StateActions.LOGIN_USER]: (state, action) => {
    return {
      ...state,
      user: action?.value,
    };
  },
  [StateActions.GET_FEATURE_FLAGS]: (state, action) => {
    return {
      ...state,
      featureFlags: action?.value,
    };
  },
  [StateActions.SET_ACTIVE_FOLDER]: (state, action) => {
    return {
      ...state,
      activeFolder: action?.value,
    };
  },
};

function appReducer(state, action) {
  return reducers[action.type](state, action);
}

export const AppContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};
