/***
 * Returns Process.env.var with the correct environment
 * usage:
 * .env file / pipeline vars
 * having two vars, ex:
 * REACT_APP_API_DEV="..."
 * REACT_APP_API_PROD="..."
 *
 * using getVar("API") returns the dev or prod env var depending on the
 * environment.
 */

const env_mapping = {
  master: "PROD",
  develop: "DEV",
  candidate: "QAT",
  experimental: "SBX",
};

export default function getVar(name) {
  const env = process.env.REACT_APP_CUSTOM_ENV || "develop";
  const mapping = env_mapping[env];
  const result = process.env[`REACT_APP_${name}_${mapping}`];
  if (!result) {
    throw new Error(
      `ENV VAR ${name} on ENV ${env} not found, returning ${result}`
    );
  }
  return result;
}
