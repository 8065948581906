export const generateParams = (obj) => {
  const names = Object.keys(obj);
  return names.map((item) => `${item}=${obj[item]}`).join("&");
};

export const stopPropagation = (cb) => {
  return function(event) {
    event.stopPropagation()
    cb()
  }
}