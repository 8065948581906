import { useEffect, useState } from "react";
/**
 *
 * @param {string} key
 * @returns [state, setState], if this setState is an object, recreate the object when you set it. it should be inmutable.
 */
export default function useLocalStorage(key) {
  const [storageState, setStorageState] = useState(null);
  useEffect(() => {
    if (storageState) localStorage.setItem(key, JSON.stringify(storageState));
  }, [storageState, key]);

  useEffect(() => {
    const state = JSON.parse(localStorage.getItem(key) ?? "false");
    if (state) setStorageState(state);
  }, [key]);

  return [storageState, setStorageState];
}
