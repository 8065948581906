import React from "react";
import { Container, TitleLabel, DescriptionLabel } from "./styles";
import { Icons } from "@sede-x/shell-ds-react-framework";

export default function NotFoundSection({ searchText }) {
  return (
    <Container>
      <Icons.Search width={60} height={60}></Icons.Search>
      <TitleLabel size={"small"}>No results for "{searchText}"</TitleLabel>
      <DescriptionLabel size={"medium"}>
        Please try a different keyword
      </DescriptionLabel>
    </Container>
  );
}
