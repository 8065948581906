import styled from "styled-components";
import { Label } from "@sede-x/shell-ds-react-framework";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AppsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Title = styled(Label)`
  font-weight: bold;
  color: ${({ theme }) => theme.text.onSurface.subtle};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 4px 16px 4px 16px;
`;
